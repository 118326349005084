{
    "version": "0.1.0",
    "name": "referal",
    "instructions": [
      {
        "name": "codePdaInit",
        "accounts": [
          {
            "name": "referrerPda",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "signer",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "code",
            "type": "string"
          },
          {
            "name": "seed",
            "type": "bytes"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "referrer",
            "type": "publicKey"
          }
        ]
      },
      {
        "name": "claim",
        "accounts": [
          {
            "name": "vestingProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clockSysvar",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "vestingAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vestingTokenAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "destinationTokenAccount",
            "isMut": true,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "seed",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      },
      {
        "name": "initVesting",
        "accounts": [
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "sysvarRent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "vestingProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "payer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "vestingAccount",
            "isMut": true,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "seed",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "length",
            "type": "u32"
          }
        ]
      },
      {
        "name": "createSvc",
        "accounts": [
          {
            "name": "vestingProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "vestingAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "mint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vestingTokenAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "sourceAccount",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "sourceTokenAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "sourceTokenAccountRef",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "destinationTokenAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "referrerPda",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "referrerTokenAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "signer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "signerPaymentAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "aggregatorFeedAccount",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "serenityAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "seed",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "currency",
            "type": "string"
          },
          {
            "name": "refCode",
            "type": "string"
          },
          {
            "name": "refSeed",
            "type": "bytes"
          }
        ]
      }
    ],
    "accounts": [
      {
        "name": "ReferrerDetails",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "referrer",
              "type": "publicKey"
            }
          ]
        }
      }
    ],
    "types": [
      {
        "name": "Sch",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "releaseTime",
              "type": "u64"
            },
            {
              "name": "amount",
              "type": "u64"
            }
          ]
        }
      }
    ],
    "errors": [
      {
        "code": 6000,
        "name": "SoldOut",
        "msg": "Sold out"
      },
      {
        "code": 6001,
        "name": "PriceNotFound",
        "msg": "Price Not Found, Please try again"
      },
      {
        "code": 6002,
        "name": "TooEarly",
        "msg": "You're too early"
      },
      {
        "code": 6003,
        "name": "SerenityNotDestinary",
        "msg": "Serenity isn't the destinary of the funds"
      },
      {
        "code": 6004,
        "name": "AmountIncorrect",
        "msg": "Invalid amount given"
      },
      {
        "code": 6005,
        "name": "InvalidTokenForTransfer",
        "msg": "Invalid Currency selected"
      },
      {
        "code": 6006,
        "name": "ReferralNotFound",
        "msg": "Referal account for vesting not found"
      },
      {
        "code": 6007,
        "name": "AmountTooLow",
        "msg": "Not enough tokens in your balance, please add enough funds."
      },
      {
        "code": 6008,
        "name": "NotAuthorized",
        "msg": "You are not authorized to perform this action"
      },
      {
        "code": 6009,
        "name": "WithdrawTooHigh",
        "msg": "Trying to deduct more lamport than there is"
      },
      {
        "code": 6010,
        "name": "NotDestinaryOfReferrerCode",
        "msg": "The referrer Account isn't accurate"
      },
      {
        "code": 6011,
        "name": "NotInWhitelist",
        "msg": "Not in Whitelist"
      },
      {
        "code": 6012,
        "name": "SaleExpired",
        "msg": "Sale is over"
      }
    ]
  }