import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl, Commitment } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Theme } from './Theme';
import { VestingUnlocker } from './VestingUnlocker';

// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.

//export  const network = WalletAdapterNetwork.Devnet;
export const network = WalletAdapterNetwork.Mainnet;

export const opts = {
    preflightCommitment: 'processed' as Commitment,
};

export const App: FC = () => {
    return (
        <Theme>
            <Context>
                <BrowserRouter>
                    <Routes>
                        <Route index element={<VestingUnlocker />} />
                        <Route path="unlock" element={<VestingUnlocker />} />
                    </Routes>
                </BrowserRouter>
            </Context>
        </Theme>
    );
};

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // You can also provide a custom RPC endpoint.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const endpoint = useMemo(() => clusterApiUrl(network), ["https://solana-api.projectserum.com/"]);
    // const endpoint = "https://ssc-dao.genesysgo.net";
    // const endpoint = "https://solana-api.projectserum.com"; // useMemo(() => clusterApiUrl(network), [network]);
    const endpoint = "https://stylish-little-hexagon.solana-mainnet.quiknode.pro/2a8c25f8530901da6bad63ed651b6003143ece2b/";


    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [new PhantomWalletAdapter(), new SlopeWalletAdapter(), new SolflareWalletAdapter({ network })],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletDialogProvider>{children}</WalletDialogProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
