
import {
  PublicKey,
} from '@solana/web3.js';
export const SERENITY_DECIMALS = 9;

// MAINNET - BETA DATA
import { TOKEN_VESTING_PROGRAM_ID } from "@bonfida/token-vesting";
export const SERENITY_MINT_ADDRESS =  new PublicKey("9pkudFiG5qhiSQQ8XatPbxZj8zBQ5m4XzHsKHMAGkkCR"); 

// DEVNET DATA
// const TOKEN_VESTING_PROGRAM_ID = new PublicKey("DLxB9dSQtA4WJ49hWFhxqiQkD9v6m67Yfk9voxpxrBs4") //dev
// export const SERENITY_MINT_ADDRESS =  new PublicKey("92hMBnnuv83kL7jTGXeBabRn3x2u1kfxCXL5Wgu5nZss");  // devnet address

export const getContractKeyFromSeed = async (seed: Buffer | Uint8Array) => {
  const seedWord = seed.slice(0, 31);
  const [vestingAccountKey] = await PublicKey.findProgramAddress(
    [seedWord],
    TOKEN_VESTING_PROGRAM_ID
  );
  return vestingAccountKey;
};


export const timeConverter = (date: Date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${day}/${month}/${year}`;
};
