import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, SYSVAR_CLOCK_PUBKEY, ConfirmOptions, Transaction } from '@solana/web3.js';
import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { BN } from '@project-serum/anchor';
import * as anchor from '@project-serum/anchor';
import GraphSection from './Graph';
import { timeConverter, SERENITY_DECIMALS } from './utils';
import { TOKEN_VESTING_PROGRAM_ID, getContractInfo, ContractInfo } from '@bonfida/token-vesting';
import { Header } from './Header';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import idl from './idl.json';
import { opts } from './App';
import { useSnackbar } from 'notistack';

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

/// MAIN
const programID = new PublicKey('14cdxb4HVGBRBg5JqRgaNZLG1r4RCgzRUApJfiR4aYnW');

/// DEV
// const programID = new PublicKey('26NriuJCR2vAMrEmrtcTMc2SzfD5eJVg184H3bLF9jMh');
// const TOKEN_VESTING_PROGRAM_ID = new PublicKey('DLxB9dSQtA4WJ49hWFhxqiQkD9v6m67Yfk9voxpxrBs4'); //dev

///

const useStyles = makeStyles(() => ({
    container: {
        width: '90%',
    },
    content: {
        textAlign: 'center',
        minHeight: '80vh',
    },
    infoContainer: {
        marginTop: '40px',
        width: '65%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    infoBox: {
        border: '2px solid gray',
        borderRadius: 10,
        margin: '20px',
        padding: '20px',
        backgroundColor: 'rgba(3, 3, 3, 0.7)',
    },
    buttonsBox: {
        margin: '10px',
        padding: '10px',
    },

    createButton: {
        width: 'min(180px, 60%)',
        margin: '10px',
        height: '36px',
    },
    inputAddress: {
        width: '95%',
    },
    graphContainer: {
        height: 'max(30vh, 200px)',
        width: '90%',
        margin: 'auto',
        fontSize: '14px',
        padding: '15px',
        marginBottom: '10px',
    },
    destinationContainer: {
        margin: '10px',
    },
    destinationLink: {
        color: 'white',
    },
}));

export const VestingUnlocker: FC = () => {
    const { connection } = useConnection();
    const wallet = useAnchorWallet();
    const { sendTransaction } = useWallet();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [contractSeed, setContractSeed] = useState('');
    const [contractInfo, setContractInfo] = useState<ContractInfo | null>(null);

    async function getProvider() {
        if (wallet != undefined && connection != undefined) {
            const provider: anchor.AnchorProvider = new anchor.AnchorProvider(connection, wallet, opts);
            // console.log('provider', provider);

            return provider;
        } else {
            return null;
        }
    }
    const readVestingInfo = async () => {
        if (!wallet) throw new WalletNotConnectedError();
        // signTransactionInstructions()
        const useSeed = stringToU8(contractSeed);
        const [vestingAccountTry, _vestingAccountNonce] = await PublicKey.findProgramAddress(
            [useSeed.slice(0, 31)],
            TOKEN_VESTING_PROGRAM_ID
        );

        let vestInfo: ContractInfo | undefined;
        try {
            vestInfo = await getContractInfo(connection, vestingAccountTry);
            /*
            console.log('vest destination: ', vestInfo.destinationAddress.toString());
            console.log('vest mint address: ', vestInfo.mintAddress.toString());
            console.log('vest schedules', vestInfo.schedules);
            vestInfo.schedules.map((schedule, idx) => {
                console.log(idx, timeConverter(new Date(schedule.releaseTime.toNumber() * 1000)), schedule.amount.toString());
            })
            */
        } catch (err) {
            console.log('Read vesting:', err)
            if (String(err).includes('Vesting contract account is unavailable')) {
                return alert('Vesting Account not found');
            } else {
                return alert('Uncaught error');
            }
        }
        // console.log('vest Info2: ', vestInfo);

        if (vestInfo == undefined) {
            return; //alert('No contract was found');
        }

        const accInfo = await connection.getAccountInfo(vestInfo.destinationAddress);
        // console.log('accInfo :', accInfo);

        setContractInfo(vestInfo);
        return vestInfo;
    };

    const stringToU8 = (str: string) => {
        const uint8array = new TextEncoder().encode(str.trim());
        // console.log('uint_array length', uint8array.length);

        if (uint8array.length > 32) {
            return uint8array.slice(0, 32);
        } else if (uint8array.length < 32) {
            const padding = new Uint8Array(32 - uint8array.length).fill(0);
            return new Uint8Array([...uint8array, ...padding]);
        } else {
            return uint8array;
        }
    };

    const unlockTokens = async () => {
        if (!wallet) throw new WalletNotConnectedError();

        const provider = await getProvider();
        if (provider == null) {
            return console.error('provider not defined');
        }
        const program = new anchor.Program(idl as anchor.Idl, programID, provider);
        if ((await connection.getBalance(provider.wallet.publicKey)) == 0) {
            return alert('Please add some Sol to your account to pay the fees');
        }

        let useSeed = stringToU8(contractSeed); //Buffer.from(contractSeed, "hex")
        const [vestingAccountTry, vestingAccountNonce] = await PublicKey.findProgramAddress(
            [useSeed.slice(0, 31)],
            TOKEN_VESTING_PROGRAM_ID
        );
        useSeed[31] = vestingAccountNonce;

        const vestInfo = await readVestingInfo();
        if (vestInfo == undefined) {
            return;
        }
        // console.log('vest Info', vestInfo);

        const vestAccATA = (
            await PublicKey.findProgramAddress(
                [vestingAccountTry.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), vestInfo.mintAddress.toBuffer()],
                SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
            )
        )[0];
        // console.log('vestAccATA:', vestAccATA.toBase58());
        let nextVesting;
        let lastAmountToRelease;
        let nextAmount;

        for (let i = vestInfo.schedules.length - 1; i >= 0; i--) {
            // console.log('scheduleVec', i, new Date(vestInfo.schedules[i].releaseTime.toNumber() * 1000));

            if (Date.now() < vestInfo.schedules[i].releaseTime.toNumber() * 1000) {
                lastAmountToRelease = 0;
                nextVesting = vestInfo.schedules[i].releaseTime.toNumber();
                nextAmount = vestInfo.schedules[i].amount.toNumber() / 10 ** 9;
            } else if (i == vestInfo.schedules.length - 1) {
                const balance = await connection.getBalance(vestAccATA);
                if (balance > 0) {
                    nextAmount = balance;
                    nextVesting = 0;
                    lastAmountToRelease = 0;
                } else {
                    nextVesting = 0;
                    nextAmount = 0;
                    lastAmountToRelease = 0;
                }
            } else {
                if (!lastAmountToRelease) {
                    lastAmountToRelease = vestInfo.schedules[i].amount.toNumber();
                } else {
                    lastAmountToRelease += vestInfo.schedules[i].amount.toNumber();
                }
            }
        }

        if (!lastAmountToRelease) {
            vestInfo.schedules.map(schedule => {
                if (schedule.amount.toNumber() > 0) {
                    lastAmountToRelease = schedule.amount.toNumber();
                }
            })
        }

        let ids = [];
        useSeed.forEach(seed => {
            ids.push(seed.toString(16))
        });
        console.log(ids.join(' '))

        console.log('vestAccount:', vestingAccountTry.toBase58());
        console.log('vestAccATA:', vestAccATA.toBase58());
        console.log('vestDestination:', vestInfo.destinationAddress.toBase58());

        if (lastAmountToRelease == undefined || nextVesting == undefined || nextAmount == undefined) {
            // console.log('Error reading the information of vesting.');
            return alert('Error reading the information of vesting.');
        } else if (lastAmountToRelease > 0) {
            try {
                const ix = await program.methods.claim(useSeed)
                    .accounts({
                        vestingProgram: TOKEN_VESTING_PROGRAM_ID,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        clockSysvar: SYSVAR_CLOCK_PUBKEY,
                        vestingAccount: vestingAccountTry,
                        vestingTokenAccount: vestAccATA,
                        destinationTokenAccount: vestInfo.destinationAddress,
                    })
                    .instruction();

                const tx = new Transaction();
                tx.add(ix);

                const {
                    context: { slot: minContextSlot },
                    value: { blockhash, lastValidBlockHeight },
                } = await connection.getLatestBlockhashAndContext();

                const signature = await sendTransaction(tx, connection, { minContextSlot });
                // console.log('signature', signature);
                const resp = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
                // console.log(resp);

                const link = 'https://explorer.solana.com/tx/' + signature;
                // console.log('Transaction confirmed, you should have received your funds, check here :', link);
                alert('Transaction confirmed, you should have received your funds, check here : ' + link);
            } catch (err) {
                const stringErr = String(err);
                console.log('Unlock tokens: ', err);

                if (stringErr.includes('Vesting contract has not yet reached release time') && nextVesting) {
                    return alert('You are too early to claim your tokens, wait until :' + new Date(nextVesting));
                } else if (stringErr.includes('Invalid vesting account key')) {
                    return alert("The vesting key is linked to a contract you don't own");
                } else if (stringErr.includes('User rejected the request.')) {
                    return alert('Cancelled by user');
                }
                else {
                    return alert('Get erorr while claim token.');
                }
            }
            // }
        } else if (nextVesting > 0) {
            return alert(
                'Nothing to release for now.\n      Next time will be:\n' +
                new Date(nextVesting * 1000) +
                '     Unlocking :\n' +
                nextAmount?.toFixed(1) +
                ' $SERSH'
            );
        } else {
            return alert('No money was found to claim\n' + 'This vesting have already been claimed.');
        }
    };

    return (
        <div className={classes.container}>
            <Header />

            <div className={classes.content}>
                <h1> Vesting Contract Creation Tool </h1>

                {wallet ? (
                    <div className={classes.infoContainer}>
                        <h3>Vesting Contract Info</h3>
                        <div className={classes.infoBox}>
                            <label>
                                {' '}
                                Contract Seed
                                <input
                                    type={'text'}
                                    value={contractSeed}
                                    name="contractSeed"
                                    className={classes.inputAddress}
                                    onChange={(e) => setContractSeed(e.target.value)}
                                />{' '}
                                <br />
                            </label>
                            <button className={classes.createButton} onClick={readVestingInfo}>
                                Read Info
                            </button>
                            <button className={classes.createButton} onClick={unlockTokens}>
                                Unlock Tokens
                            </button>

                            {contractInfo && (
                                <div>
                                    <div className={classes.graphContainer}>
                                        <GraphSection
                                            data={contractInfo?.schedules.map((s) => {
                                                const numerateur = new BN(s.amount.toString());
                                                const denominateur = new BN(Math.pow(10, SERENITY_DECIMALS).toString());
                                                return {
                                                    amount: numerateur.div(denominateur).toNumber(),
                                                    time: timeConverter(new Date(s.releaseTime.toNumber() * 1000)),
                                                };
                                            })}
                                            xKey="time"
                                            yKey="amount"
                                        />
                                    </div>
                                    <div className={classes.destinationContainer}>
                                        <a
                                            className={classes.destinationLink}
                                            target="_blank"
                                            href={`https://explorer.solana.com/address/${contractInfo?.destinationAddress.toString()}`}
                                            rel="noreferrer"
                                        >
                                            Destination Token Address: {contractInfo?.destinationAddress.toString()}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={classes.infoContainer}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3>Connect wallet to proceed</h3>
                    </div>
                )}
            </div>
        </div>
    );
};
